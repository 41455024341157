<template lang="pug">
  include ../Bemto

  div
    +b.i-filter-modal-trigger
      +e.BUTTON.button(
        type="button"
        @click="showFilterModal()"
      )
        app-icon.icon-filter(name="icon-filter" size="lg")
    component(
      v-bind="isDesktop ? {} : modalAttrs"
      :is="isDesktop ? 'div' : 'modal'"
    )
      +b.DIV.filter-selected-wrapper(v-if="!isDesktop")
        +b.DIV.filter-header
          +b.P.filter-header__text {{ _("Фільтр") }}
          +b.DIV(
            @click.prevent='hideFilterModal()'
          )
            +b.APP-ICON(name="icon-cross")
        +b.DIV(v-if="value && Object.values(value)?.length")
          +b.P.filter-selected-title {{ _("Обрані фільтри") }}
          +e.selected
            cell
              search-chips(
                @skip='clear'
                :all='filters'
                :filters='value'
                @update:filters="changeFilters"
              )
      filters-renderer.i-filter(
        :is-desktop="isDesktop"
        :widgets-map="$options.widgetsMap"
        :prepared-filters="filters"
        :update-multiple-values="updateMultipleValues"
        :get-multiple-values="getMultipleValues"
        :getOtherValue="getOtherValue"
        :updateOtherValue="updateOtherValue"
        :update-value="updateValue"
        :value="value"
        :hasOtherFilter="hasOtherFilter"
      )
      +b.i-filter-apply-action
        +b.BUTTON.el-btn--fill-black(
          type="button"
          @click.prevent="() => { $emit('input', {}); hideFilterModal(); }"
        ) {{ _("Усунути фільтри") }}
        +b.BUTTON.el-btn--fill(
          type="button"
          @click.prevent='hideFilterModal()'
        ) {{ _("Показати результати") }}
</template>

<script>
import SearchChips from '@app/List/Chips'
import UiMixin from './mixins/UiMixin'
import FiltersRenderer from './FiltersRenderer'

export default {
  mixins: [UiMixin],
  components: {
    FiltersRenderer,
    SearchChips,
  },

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'additionalResource',
    'label',
    'hasOtherFilter',
    'changeFilters',
  ],
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', () => {
      this.checkWidth()
    })
  },
  methods: {
    checkWidth() {
      this.isDesktop = window.innerWidth >= this.windWidth;
    },
    showFilterModal() {
      this.$modal.show('filters-modal');
      this.$body.classList.add('is-active-filters-modal');
    },
    hideFilterModal() {
      this.$modal.hide('filters-modal');
      this.$body.classList.remove('is-active-filters-modal');
    },
  },
}
</script>
<style scoped>
.icon-filter > .ds-inliner__body > svg {
  fill: #212121
}
.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #EEEEEE;
}

.i-filter {
  min-height: calc(100vh - 173px);
}

.filter-header__text {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.filter-footer {
  padding: 24px 24px 40px !important;
  box-shadow: 0px -3px 24px 0px #0000001A !important;

}
.chips-filter-mobile {
  display: block;

}
.filter-selected-wrapper{
  padding: 0 21.5px 0;
}

.filter-selected-title {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  padding-top: 24px;
  padding-bottom: 16px;
}

@media (max-width: 500px) {
  .chips-filter-mobile {
    display: none;

  }
}

</style>
