<template lang="pug">
  include ../../Bemto
  div
    +b.checkbox-label(
      v-for="(option, index) in genderOptions"
      :key="index"
      :style="{marginBottom: '15px'}"
    )
      +e.INPUT(
        type='checkbox'
        :name="'gender-' + index"
        :id="'gender-' + index"
        v-model="checked"
        :value="option.value"
        @change="handleGenderChange(option.value)"
      )
      +b.LABEL.text.--size-sm.--pointer(
        :for="'gender-' + index"
      )
        +b.SPAN {{ option.displayName }}
        +b.SPAN.text.--color-gray.--weight-default.ma--l-2xxs(v-if="option.countTalents !== undefined") {{ `(${option.countTalents})` }}
</template>

<script>
export default {
  name: 'GenderCheckboxWidget',
  props: {
    value: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checked: [],
      genderOptions: this.filter.options,
    };
  },
  mounted() {
    this.updateCheckedState(this.value)
  },
  watch: {
    value(newVal) {
      this.updateCheckedState(newVal);
    },
  },
  methods: {
    handleGenderChange(selectedValue) {
      if (1 < this.checked.length) {
        this.checked = [selectedValue];
      }
      this.$emit('input', this.checked[0]);
    },
    updateCheckedState(newVal) {
      if (null !== newVal) {
        this.checked = [newVal];
      } else {
        this.checked = [];
      }
    },
  },
};
</script>

<style scoped>
.checkbox-label input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1.5px solid #CCCCCC;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #7069A9;
  border: none;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.394 4.33312L6 12.7271L1.606 8.33312L2.66666 7.27246L6 10.6058L13.3333 3.27246L14.394 4.33312Z" fill="white"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
