<template lang="pug">
include ../../Bemto
+b.el-accordion
  row(
    :style="{cursor: 'pointer'}"
    @click='status = !status'
    :justify="'between'"
  )
    cell(
      :cols="'10'"
    )
      +b.P.text.--size-sm(
        v-if='id'
        @click="goToProject()"
        :class="[status ? ['text--weight-bold'] : 'text']"
      ) {{ name }}
      +b.P.text.--size-sm(
        v-else
        :class="[status ? ['text--weight-bold'] : 'text']"
      ) {{ name }}
    cell
      div(
        @click="id ? goToProject() : () => {}"
        :class="[status ? 'arrow-down' : 'arrow-up']"
      )
        +b.APP-ICON.text.--size-2lg(
          name="icon-sideleft"

        )
  vue-accordion(
    :expanded='status'
  )
    slot(name='content')
</template>

<script>

import { ROUTES_NAMES } from '@routes'

export default {
  name: 'accordion-component',
  props: {
    name: {},
    id: {
      default: null,
    },
    isOpen: {},
  },
  data() {
    return {
      INFO_PROJECT: ROUTES_NAMES.PROJECT_INFO,
      status: this.isOpen || false,
    }
  },
  mounted() {
  },
  methods: {
    goToProject() {
      if (!this.status) {
        this.$router.push({ name: this.INFO_PROJECT, params: { id: this.id } })
      }
    },

  },
}
</script>
