import { render, staticRenderFns } from "./Ui.vue?vue&type=template&id=08f69337&scoped=true&lang=pug&"
import script from "./Ui.vue?vue&type=script&lang=js&"
export * from "./Ui.vue?vue&type=script&lang=js&"
import style0 from "./Ui.vue?vue&type=style&index=0&id=08f69337&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f69337",
  null
  
)

export default component.exports