import Userbar from './Userbar'
import ListBar from './ListBar'
import ProjectBar from './ProjectBar'
import ProjectEdit from './ProjectEdit'
import EditTemplate from './EditTemplate'
import FavoritesBar from './FavoritesBar'
import CastingBar from './CastingBar.vue'

export default function install(Vue) {
  Vue.component(Userbar.name, Userbar)
  Vue.component(ListBar.name, ListBar)
  Vue.component(ProjectBar.name, ProjectBar)
  Vue.component(ProjectEdit.name, ProjectEdit)
  Vue.component(EditTemplate.name, EditTemplate)
  Vue.component(FavoritesBar.name, FavoritesBar)
  Vue.component(CastingBar.name, CastingBar)
}
