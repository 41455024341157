<template lang="pug">
  include ../../Bemto
  //- ui-click-outside(:do='hideBar')
  div.card-casting-bar(:key="id")
    +b.casting-bar
      +b.P.casting-bar-title {{ _("Обрати кастинг:") }}
      +b.DIV.casting-bar-list(v-if="filteredProjectsList?.length > 0")
        ul
          li(v-for="(project, index) in filteredProjectsList" :key="index")
            label.checkbox-label
              input(
                type="checkbox"
                :checked="setCheckboxValue(id , project)"
                @change="projectEventHandler($event.target.checked, project)"
              )
              span {{ project.name }}
      +b.P.subtitle(v-else) {{ _('У вас ще немає жодного створеного кастингу, щоб додати акторів. Бажаєте створити кастинг зараз?') }}

      +b.DIV.casting-bar-button__wrapper
        +b.DIV.casting-bar-button(
          v-if="filteredProjectsList?.length > 0"
          @click.prevent="hideBar"
          v-close-popover.all
        ) {{ _("Додати актора") }}
        +b.DIV.casting-bar-button(v-else @click="redirectToProjects") {{ _("Створити кастинг")}}
</template>

<script>
import {
  addTalentsToProjectAPI,
  removeTalentsFromProjectApi,
} from '@requests/services/services'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'casting-bar',
  props: ['id', 'projects'],
  computed: {
    ...mapGetters('unionProjectsList', {
      projectsList: 'getActiveProjectsList',
    }),
    filteredProjectsList() {
      return this.projectsList.filter(el => -1 !== el.name.toLowerCase())
    },
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('unionProjectsList', [
      'projectListRequest',
    ]),
    hideBar() {
      this.$emit('close-bar')
    },
    redirectToProjects() {
      window.location.href = '/cabinet/layout/projects?status=0';
    },

    projectEventHandler(e, item) {
      if (e) {
        this.addToProject(this.id, item)
      } else {
        this.removeFromProject(this.id, item)
      }
    },
    addToProject(id, item) {
      const params = {
        ids: [id],
      }
      addTalentsToProjectAPI.execute({ id: item.id }, params).then(() => {
        this.projectListRequest()
      })
    },
    removeFromProject(id, item) {
      const params = {
        ids: [id],
      }
      removeTalentsFromProjectApi.execute({ id: item.id }, params).then(() => {
        this.projectListRequest()
      })
    },
    setCheckboxValue(id, project) {
      return !!(project.talents || []).find(el => el.id === id)
    },
  },
}
</script>

<style>
.card-casting-bar {
  position: relative;
}

.casting-bar {
  border-radius: 8px;
  background-color: #ffffff; /* Replace $c-white with hex color */
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
  left: -100px;
  top: 16px;
  width: 252px;
}

.casting-bar__wrapper {
  padding: 15px;
}

.casting-bar__new-proj {
  border-bottom: 1px solid #d3d3d3; /* Replace $c-light-gray with hex color */
  padding: 15px 0;
}

.casting-bar__bar-input {
  border: 1px solid #d3d3d3; /* Replace $c-light-gray with hex color */
  border-radius: 4px;
  width: 100%; /* Use width instead of 'w' */
  padding: 0 10px;
  height: 48px;
}

.casting-bar-title {
  font-size: 14px;
  line-height: 16.8px;
  color: #0C0C0E;
  font-weight: 600;
  text-align: start;
  padding: 21px 16px 28px;
}

.casting-bar-button__wrapper {
  padding: 16px;
  border-top: 1px solid #CCCCCC;
}

.casting-bar-button {
  display: flex;
  padding: 0 16px;
  border: 2px solid #63B000;
  border-radius: 40px;
  font-size: 14px;
  line-height: 16.8px;
  color: #63B000;
  width: fit-content;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
}

.casting-bar-button:hover {
  border: 2px solid #5CA300;
  color: #5CA300;
}

.casting-bar-list {
  padding: 0 16px;
}
.castings-list {
  margin-bottom: 16px;
}

.castings-list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.castings-list li {
  margin-bottom: 8px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  appearance: none; /* Remove default styling */
  width: 20px;
  height: 20px;
  border: 1.5px solid #CCCCCC;
  border-radius: 4px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #7069A9;
  border: none;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px; /* Adjust size as needed */
  height: 16px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.394 4.33312L6 12.7271L1.606 8.33312L2.66666 7.27246L6 10.6058L13.3333 3.27246L14.394 4.33312Z" fill="white"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}

.subtitle {
  color: #333333;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  padding: 0 16px 24px;
}
</style>
