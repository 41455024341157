<template lang="pug">
include ../../Bemto
div(v-if="talent")
  +b.favorite-card-desktop
    +e.photo
      +e.A.link(
        :href="talent.link"
      )
        picture(
          @mouseover="talent.secondPreviewImage.base ? hover = true : hover = false"
          @mouseleave="hover = false"
        )
          template(v-if="talent.firstPreviewImage.base")
            template(v-if="talent.secondPreviewImage.base ")
              +e.SOURCE(:srcset="hover ? talent.secondPreviewImage.webp : talent.firstPreviewImage.webp")
              +e.IMG.photo(:src="hover ? talent.secondPreviewImage.base : talent.firstPreviewImage.base", :alt='talent.firstName')
            template(v-else)
              +e.SOURCE(:srcset="talent.firstPreviewImage.webp")
              +e.IMG.photo(:src="talent.firstPreviewImage.base", :alt='talent.firstName')
          +e.IMG.photo(v-else :src="'https://via.placeholder.com/400'", :alt='talent.firstName')
        +b.favorite-checkbox
          +e.INPUT.checkbox.none--xs(
            type='checkbox'
            :name='talent.id'
            :id='talent.id'
            v-model='value'
            @change="$emit('mark-data', value, talent.id)"
          )
          +e.LABEL.fake-checkbox(
            :for='talent.id'
          )
        +b.remove-icon(@click.prevent="removeTalents")
          +b.SVG
            path(:d="trashIcon" fill="#212121")
    +b(style='margin-top: 16px')
      row(
        :justify="'between'"
        align="center"
        style="width: 100%"
      )
        cell(:cols="[]")
          +b.A.text.--font-serif.--color-black.--size-lg.--weight-bold.--pointer-green.talent-name(
            :href="talent.link"
          ) {{ talent.firstName }}
          row.card-data
            cell(style="padding: 0 0 0 6px")
              +b.P.talent-property {{ _('ID: ') + talent.id }}
            cell
              +b.delimiter
            cell(style="padding: 0 0 0 6px")
              +b.P.talent-property {{ _('Age: ') + talent.age }}
        +b
          row(align="center" style="gap: 8px")
            +b.icon-wrap
              modal-trigger(
                name='TalentPreview'
                url='modals/TalentPreview'
                :classes="['vm--modal_xxl']"
                :talent='talent'
              )
                template(#default='{ open }')
                  div(
                    @click.prevent='open'
                    style='cursor: pointer'
                  )
                    app-icon(name="icon-play" size="xl")
            +b.icon-wrap
              v-popover(
                :popover-class="'tooltip-modal'"
                :delay="{ show: 500, hide: 500 }"
                :key='talent.id'
              )
                app-icon(name="icon-plus-inactive" size="xl")
                template(#popover)
                  project-bar.card-projects-bar(
                    :id='talent.id'
                    :key='talent.id'
                  )
      row.ma--t-2xs(
        v-if='characteristic'
      )
        cell.ma--b-xs(
          v-for='skill, index in talent.skills'
          :key='index'
        )
          +b.panel.--br-xl.pa--round-2xxs.characteristic-item
            +b.P.text.--size-xs {{ characteristic('skills', skill) }}

</template>
<script>

import { mapGetters } from 'vuex'
import { removeFavoriteTalentsAPI } from '@requests/services/services';
import ConfirmModal from '@app/modals/ConfirmModal.vue';

export default {
  name: 'favorite-card',
  props: {
    talent: {},
    state: {},
    favoriteRequest: Function,
  },
  data() {
    return {
      hover: false,
      value: null,
      visibleProjectsList: false,
      modalDataTalent: {},
      trashIcon: 'M3.30775 17.4997C2.81058 17.4997 2.385 17.3227 2.031 16.9687C1.677 16.6147 1.5 16.1892 1.5 15.692V2.99973H1.25C1.0375 2.99973 0.859417 2.92782 0.71575 2.78398C0.571917 2.64015 0.5 2.46198 0.5 2.24948C0.5 2.03682 0.571917 1.85873 0.71575 1.71523C0.859417 1.57157 1.0375 1.49973 1.25 1.49973H5C5 1.2549 5.08625 1.04624 5.25875 0.873735C5.43108 0.701402 5.63967 0.615234 5.8845 0.615234H10.1155C10.3603 0.615234 10.5689 0.701402 10.7413 0.873735C10.9138 1.04624 11 1.2549 11 1.49973H14.75C14.9625 1.49973 15.1406 1.57165 15.2843 1.71548C15.4281 1.85932 15.5 2.03748 15.5 2.24998C15.5 2.46265 15.4281 2.64073 15.2843 2.78423C15.1406 2.9279 14.9625 2.99973 14.75 2.99973H14.5V15.692C14.5 16.1892 14.323 16.6147 13.969 16.9687C13.615 17.3227 13.1894 17.4997 12.6923 17.4997H3.30775ZM13 2.99973H3V15.692C3 15.7818 3.02883 15.8556 3.0865 15.9132C3.14417 15.9709 3.21792 15.9997 3.30775 15.9997H12.6923C12.7821 15.9997 12.8558 15.9709 12.9135 15.9132C12.9712 15.8556 13 15.7818 13 15.692V2.99973ZM6.15425 13.9997C6.36675 13.9997 6.54483 13.9279 6.6885 13.7842C6.832 13.6404 6.90375 13.4622 6.90375 13.2497V5.74973C6.90375 5.53723 6.83183 5.35907 6.688 5.21523C6.54433 5.07157 6.36617 4.99973 6.1535 4.99973C5.941 4.99973 5.76292 5.07157 5.61925 5.21523C5.47575 5.35907 5.404 5.53723 5.404 5.74973V13.2497C5.404 13.4622 5.47583 13.6404 5.6195 13.7842C5.76333 13.9279 5.94158 13.9997 6.15425 13.9997ZM9.8465 13.9997C10.059 13.9997 10.2371 13.9279 10.3807 13.7842C10.5243 13.6404 10.596 13.4622 10.596 13.2497V5.74973C10.596 5.53723 10.5242 5.35907 10.3805 5.21523C10.2367 5.07157 10.0584 4.99973 9.84575 4.99973C9.63325 4.99973 9.45517 5.07157 9.3115 5.21523C9.168 5.35907 9.09625 5.53723 9.09625 5.74973V13.2497C9.09625 13.4622 9.16817 13.6404 9.312 13.7842C9.45567 13.9279 9.63383 13.9997 9.8465 13.9997Z',
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getSingleCharacteristic',
    }),
  },
  watch: {
    state() {
      this.value = this.state
    },
  },
  methods: {
    removeTalents() {
      const pr = arg => new Promise(resolve => {
        if (arg) {
          resolve(arg)
        }
      }).then(() => {
        const data = {
          ids: this.talent.id,
        }
        removeFavoriteTalentsAPI.execute(data).then(() => {
          this.favoriteRequest()
        })
      })
      this.$modal.show(ConfirmModal,
        {
          promise: pr,
          confirmingText: this._('Are u sure to delete talents from list'),
        },
        {
          adaptive: true,
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.icon-wrap {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;

  margin: 12px;
}

.card-data {
  margin-top: 0;
}

.talent-property {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.talent-name {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.remove-icon {
  position: absolute;
  top: 21px;
  left: 24px;

  cursor: pointer;

  z-index: 5;

  svg {
    width: 16px;
    height: 18px;
  }
}

.characteristic-item {
  background: #EBEBFE;
  border-radius: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  padding: 2px 6px;
}
</style>
