<template lang="pug">
include ../Bemto
+b.cabinet
  cabinet-admin(
    v-if="role === 'admin'"
  )
  cabinet-admin(
    v-if="role === 'client'"
  )
  cabinet-talent(
    v-if="role === 'talent'"
  )
  welcome-modal
</template>

<script>

import AdminLayout from './lk-admin/AdminLayout'
import ClientMain from './lk-client/ClientMain'
import TalentLayout from './lk-talent/TalentLayout'
/* eslint-disable import/order */
import ChatBotModal from '@app/modals/ChatBotModal'
import WelcomeModal from '@app/modals/WelcomeModal';

export default {
  name: 'app-cabinet',
  components: {
    WelcomeModal,
    'cabinet-admin': AdminLayout,
    'cabinet-client': ClientMain,
    'cabinet-talent': TalentLayout,
  },
  data() {
    return {
      role: window.ROLE,
      BOT_LINK: window.BOT_LINK,
    }
  },
  mounted() {
    const isModalShow = sessionStorage.getItem('modalShow')
    if (!isModalShow && this.BOT_LINK && 'admin' !== this.role) {
      this.$modal.show(ChatBotModal, { info: this.$attrs }, { classes: ['vm--modal_md'] })
    }
  },
}
</script>
