<template lang="pug">
include ../Bemto
div(
  :style="{width: '100%', maxWidth: '1190px', margin: '0 auto'}"
)
  +b.g-row--space_sm.--justify_between.--align_center.ma--b-sm
    +b.g-cell.g-cols
      +b.P.text.go-prev--clear.text.--color-black.back-wrapper(
        @click='goBack(talentsCatalogUrl)'
      ) {{ _('Back') }}
    +b.g-cell.g-cols(
      v-if="isAdmin && !isVerified"
    )
      +b.BUTTON.moderate-talent-profile(
        type="button"
        @click="moderate"
      )
        +e.P.text {{ _("Модерувати") }}
  +b.panel.--bg-white.--br-default.pa--round-lg-md
    +b.talent-detail-wrapper
      +e.photo-block
        +b.talent-photo-view.talent-image-box(
          ref="swipe"
          :style="{width: activePhoto.videoFile ? '100%' : '', height: activePhoto.videoFile ? 'fit-content' : ''}"
        )
          +b.controls
            +b.controls-left(
              @click='prevPhoto(currentPhoto)'
            )
              +b.IMG.chevron_icon(src="/static/img/chevron_forward.svg")
            +b.controls-right(
              @click='nextPhoto(currentPhoto)'
            )
              +b.IMG.chevron_icon(src="/static/img/chevron_forward.svg")
          light-box.light-box-wrapper(
            :source="photos"
          )
            template(v-slot:default='{ show }')
              video-block(v-if="activePhoto.videoFile" :videoSrc="activePhoto.videoFile")
              +b.image-wrap(
                v-else
              )
                +b.IMG.talent-photo-view__photo(
                  :src='activePhoto'
                  @click='show(currentPhoto)'
                )
                +b.div.image-wrap_loader(
                  v-if="!isImageLoad"
                )
                  +b.span.image-wrap_loader_spinner
          //- +b.scale-btn
          //-   +b.P.icon-search
        +b.pseudo-slider
          //+e.left(
          //  @click='prevPhoto(currentPhoto)'
          //)
          +e.photo-block(
            ref='photoBlock'
          )
            +e.item(
              :class="[currentPhoto != index ? 'blur' : '']"
              v-for="(item, index) in combinedPhotos"
              :key='index'
              @click='switchSlide(item, index)'
            )
              .pseudo-slider__image.dummy-video-preview(
                v-if="item.videoFile"
              )
              picture(v-else)
                source(
                  v-if="item.imageLinks && item.imageLinks.previewWebp"
                  :srcset="item.imageLinks.previewWebp || 'https://via.placeholder.com/400'" type='image/webp'
                )
                img.pseudo-slider__image(
                  :src="item.imageLinks && item.imageLinks.previewBase || 'https://via.placeholder.com/400'"
                  alt='avatar'
                )
          //+e.right(
          //  @click='nextPhoto(currentPhoto)'
          //)
        +b.--br-default.pa--t-sm.pa--b-sm.pa--l-lg.pa--r-lg.ma--t-lg.contacts-info.info-desktop(:class="{'contact-disabled': !isShowTalentContent}")
          +b.DIV(:class=" {'contacts-info-buy': !hasContactAccess, 'contacts-info-buy-disabled': hasContactAccess, 'contacts-info-disabled': !hasContactAccess }")
            modal-trigger(
              name='TalentShareModal'
              url='modals/TalentShareModal'
              :classes="['vm--modal_sm']"
              :modalData="{ talent }"
            )
              template(#default="{ open }")
                +b.DIV.add-contact-btn(@click.prevent='open')
                  app-icon(name="icon-plus-box" size="xl")
                  +b.SPAN {{ _('Відкрити контакти') }}

          +b.DIV.contact-header-wrapper
            +b.P.text.--weight-bold.--size-lg.--font-serif.contact-header-text  {{ _('Contacts') }}
            +b.DIV.contact-header-icon-wrapper
              +b.A.ds-link(
                v-if="isHidden || talent.facebookLink != ''"
                target='_blank'
                :href='talent.facebookLink'
                @click="isHidden ? openSubscriptionInfo() : () => {}"
              )

                app-icon(name="icon-facebook-outlined" size="xl")
              +b.A.ds-link(
                v-if="isHidden || talent.instagramLink != ''"
                target='_blank'
                :href='talent.instagramLink'
                @click="isHidden ? openSubscriptionInfo() : () => {}"
              )
                app-icon(name="icon-instagram-outlined" size="xl")
              +b.A.ds-link(
                v-if="isHidden || talent.telegramLink != ''"
                target='_blank'
                :href='talent.telegramLink'
                @click="isHidden ? openSubscriptionInfo() : () => {}"
              )
                app-icon(name="icon-telegram-outlined" size="xl")

              //- +b.A.ds-link(
              //-   v-if="isHidden || talent.tiktokLink != ''"
              //-   target='_blank'
              //-   :href='talent.tiktokLink'
              //-   @click="isHidden ? openSubscriptionInfo() : () => {}"
              //- )
              //-   app-icon(name="icon-tiktok" size="2xl")
          row.ma--t-2sm(
            :align="'center'"
          )
            cell(
              :cols="['12', '6-sm']"
            )
              row(appearance="nowrap")
                cell(
                  :cols="['2']"
                )
                  app-icon(name="icon-location" size="xl")
                cell
                  +b.P {{ talent.city }}
              row(appearance="nowrap").ma--t-sm
                cell(
                  :cols="['2']"
                )
                  app-icon(name="icon-phone-outlined" size="xl")
                cell
                  COMPONENT(
                    :is="isHidden ? 'a' : 'p'"
                    :class="{ 'ds-link': isHidden }"
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  ) {{ talent.phone }}
            cell(
              :cols="['12', '6-sm']"
            )
              row(
                :justify="'start'"
              )
      +e.data-block
        +b.talent-data(
          :style="{paddingBottom: '10px'}"
        )
          +e.name(
            v-if='characteristic'
          )
            row.name-wrapper(justify="between", align="center" appearance="nowrap")
              cell(:cols="[]")
                +b.H1.text.--font-serif.--weight-bold.name-text {{ talent.firstName }} {{ hasContactAccess ? talent.lastName : '' }}
              cell(
                v-if="userId && userId == talent.id"
                cols="narrow"
              )
                +b.A.edit-talent-profile(
                  href="/cabinet/profile"
                )
                  +b.ds-inliner--size_sm
                    +e.ICON-PEN.body
                  +e.text {{ _("Редагувати") }}
              cell(
                v-if="isAdmin"
                cols="narrow"
              )
                +b.A.edit-talent-profile(
                  :href="`/cabinet/talents/${talent.id}/edit`"
                )
                  +b.ds-inliner--size_sm
                    +e.ICON-PEN.body
                  +e.text {{ _("Редагувати") }}
              cell(
                v-if="isClient || isAdmin"
                cols="narrow"
              )
                modal-trigger(
                  name='TalentShareModal'
                  url='modals/TalentShareModal'
                  :classes="['vm--modal_sm']"
                  :modalData="{ talent }"
                )
                  template(#default="{ open }")
                    +b.DIV.share-icon(
                      @click.prevent='open'
                    )
                      app-icon(name="icon-share" size="xl")
            +b.SPAN.id-label {{ 'ID: ' + talent.id + ' ' }}
            span(
              v-if="characteristic && Object.keys(characteristic).includes('skills')"
            )
              +b.SPAN.skill-label(
                v-for='item, idx in talent.skills'
                :key='idx'
              ) {{ singleChar('skills', item) + (talent.skills.length > idx+1 ? ', ' : ' ') }}
        +b.talent-data(v-if="isClient || isAdmin")
          hr
          row.ma--t-2xs.ma--b-2xs(
            align="center"
          )
            cell(
            )
              modal-trigger(
                name='AddToCastingModal'
                url='modals/AddToCastingModal'
                :classes="['vm--modal_sm']"
                :modalData="{ projects }"
              )
                template(#default="{ open }")
                  +b.DIV.casting-add-wrapper(
                      @click.prevent='open'
                    )
                    app-icon(name="icon-add-circle" size="xl")
                    +b.SPAN {{ _('Додати у кастинг') }}

            cell.heart-wrapper(
            )
            favorites-bar(
              :id='talent.id'
              :key='talent.id'
              v-slot="{ isFavorite, toggle }"
            )
              .card-favorites-bar(
                :class="{ 'is-active': isFavorite }"
              )
                +b.APP-ICON.icon-heart(
                  v-if="!isFavorite"
                  :size="['xl']"
                  name="icon-heart-talent"
                  @click.prevent="toggle()"
                )
                +b.APP-ICON.icon-heart(
                  v-else
                  :size="['xl']"
                  name="icon-heart-talent-active"
                  @click.prevent="toggle()"
                  :class="{ 'heart-active': isFavorite }"
                )
        +b.talent-data(v-if="isAdmin")
          hr
          row.ma--t-2xs.ma--b-2xs(
            align="center"
          )
            cell(
              :cols="['5']"
            )
              v-popover(
                :popover-class="'tooltip-modal'"
                :delay="{ show: 500, hide: 500 }"
                :key='talent.id'
              )
                +b.P.bar-label--proj.text.--size-sm.--pointer-green.--weight-bold(
                ) {{ _('To projects') }}
                template(#popover)
                  project-bar.talent-projects-bar(
                    :id='talent.id'
                    :key='talent.id'
                  )
            cell(
              :cols="['5']"
            )
              favorites-bar(
                :id='talent.id'
                :key='talent.id'
                v-slot="{ isFavorite, toggle }"
              )
                +b.g-row--align_center.--space_sm.card-favorites-bar(
                  :class="{ 'is-active': isFavorite }"
                )
                  +b.g-cell.g-cols
                    +b.APP-ICON.text.--pointer-green(
                      size="xl"
                      name="icon-heart"
                      @click.prevent="toggle()"
                    )
                  +b.g-cell.g-cols
                    +b.P.green-text(
                      :class="'text-green'"
                      @click.prevent="toggle()"
                    ) {{ _(isFavorite ? 'Обране': 'To favorites') }}
        hr
        row(
          v-if='characteristic'
          space="sm"
          appearance="spaced"
          align="center"
        )
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Gender') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('genders')"
            ) {{ singleChar('genders', talent.gender) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Age') }}
          cell(
            :cols="'7'"
          )
            +b.P.text {{ talent.age }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Hairs') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('hairColors')"
            ) {{ singleChar('hairColors', talent.hairColor) + ', ' + singleChar('hairTypes', talent.hairType).toLowerCase() }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Eyes color') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('eyesColors')"
            ) {{ singleChar('eyesColors', talent.eyesColor) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Skin color') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('skinColors')"
            ) {{ singleChar('skinColors', talent.skinColor) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Body type') }}
          cell(
            :cols="'7'"
          )
            +b.P.text(
              v-if="characteristic && Object.keys(characteristic).includes('bodyTypes')"
            ) {{ singleChar('bodyTypes', talent.bodyType) }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Height') }}
          cell(
            :cols="'7'"
          )
            +b.P.text {{ talent.height }}
          cell(
            :cols="'5'"
          )
            +b.P.text.--weight-bold {{ _('Weight') }}
          cell(
            :cols="'7'"
          )
            +b.P.text {{ talent.weight }}
          //- cell(
          //-   :cols="['12']"
          //- )
            +b.P.text.--weight-bold {{ _('Type') }}
            row.card-data
              cell(
                v-for='tag in talent.types'
                :key='tag'
              )
                +b.card-tag.text.--size-xs(
                  v-if="characteristic && Object.keys(characteristic).includes('talentTypes')"
                ) {{ singleChar('talentTypes', tag) }}
          cell(cols="12")
            hr
          cell.ma--t-xxs.ma--b-xxs(
            :cols="['12']"
          )
            +b.H2.text.--weight-bold.--size-lg.cell-wrapper {{ _('Clothes') }}
          cell(
            :cols="['12']"
          )
            row
              cell(
                :cols="'4'"
              )
                +b.P.text.--weight-bold {{ _('Top') }}
                +b.P.text(
                  v-if="characteristic && Object.keys(characteristic).includes('clothingSizes')"
                ) {{ singleChar('clothingSizes', talent.clothingSizeTop) }}
              cell(
                :cols="'4'"
              )
                +b.P.text.--weight-bold {{ _('Bottom') }}
                  +b.P.text(
                    v-if="characteristic && Object.keys(characteristic).includes('clothingSizes')"
                  ) {{ singleChar('clothingSizes', talent.clothingSizeBottom) }}
              cell(
                :cols="'4'"
              )
                +b.P.text.--weight-bold {{ _('Shoe') }}
                +b.P.text {{ talent.shoeSize }}
          cell(cols="12")
            hr
          cell(
            v-if="talent.specials && talent.specials.length"
            :cols="['12']"
          )
            +b.P.text.--weight-bold.--size-lg.cell-wrapper {{ _('Special') }}
            row.card-data(
              appearance="spaced"
              space="xs"
            )
              cell(
                v-for='tag in talent.specials'
                :key='tag'
              )
                +b.card-tag.text.--size-xs(
                  v-if="characteristic && Object.keys(characteristic).includes('specials')"
                ) {{ singleChar('specials', tag) }}
          cell(cols="12")
            hr
          cell(cols="12")
            +b.P.text.--weight-bold.--size-lg.cell-wrapper {{ _('Інше') }}

          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Закордонний паспорт') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.icon.icon-check-mark.text--color-green(v-if="talent.biometricPassport")
            +b.P.text(v-else) {{ _('Не маю') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Права водія') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.icon.icon-check-mark.text--color-green(v-if="talent.driverLicense")
            +b.P.text(v-else) {{ _("Не маю") }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Іноземна мова') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text {{ knowledgeOfLanguage }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Досвід фотозйомки') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text {{ singleChar('professionalPhotoExperience', talent.professionalPhotoExperience) }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text.--weight-bold {{ _('Досвід відеозйомки') }}
          cell.ma--t-2xxs(
            :cols="'6'"
          )
            +b.P.text {{ singleChar('professionalVideoExperience', talent.professionalVideoExperience) }}
        +b.panel.--bg-sky-blue.--br-default.ma--t-sm.pa--round-lg.contact--mobile(:class="{'contact-disabled': !isShowTalentContent}")
          +b.DIV(:class=" {'contacts-info-buy': !hasContactAccess, 'contacts-info-buy-disabled': hasContactAccess, 'contacts-info-disabled': !hasContactAccess }")
            modal-trigger(
              name='TalentShareModal'
              url='modals/TalentShareModal'
              :classes="['vm--modal_sm']"
              :modalData="{ talent }"
            )
              template(#default="{ open }")
                +b.DIV.add-contact-btn(@click.prevent='open')
                  app-icon(name="icon-plus-box" size="xl")
                  +b.SPAN {{ _('Відкрити контакти') }}
                  +b.DIV.contact-header-wrapper
          +b.DIV.contact-header-icon-wrapper
            +b.P.text.--weight-bold.--size-lg.--font-serif.contact-header-text  {{ _('Contacts') }}
          row.ma--t-2lg(
            :align="'center'"
          )
            cell(
              :cols="['12', '6-sm']"
            )
              row(appearance="nowrap")
                cell(
                  :cols="['2']"
                )
                  +b.APP-ICON.text.--color-light-blue(name="icon-point")
                cell(cols="auto")
                  +b.P.text.--size-sm {{ talent.city }}
              row(
                :style="{marginTop: '20px'}"
              )
                cell(
                  :cols="['2']"
                )
                  +b.APP-ICON.text.--color-light-blue(name="icon-phone")
                cell(cols="auto")
                  +b.COMPONENT.text.--size-sm(
                    :is="isHidden ? 'a' : 'p'"
                    :class="{ 'ds-link': isHidden }"
                    @click="isHidden ? openSubscriptionInfo() : () => {}"
                  ) {{ talent.phone }}

              +b.DIV.contact-header-icon-wrapper.ma--t-sm
                +b.A.ds-link(
                  v-if="isHidden || talent.facebookLink != ''"
                  target='_blank'
                  :href='talent.facebookLink'
                  @click="isHidden ? openSubscriptionInfo() : () => {}"
                )

                  app-icon(name="icon-facebook-outlined" size="2xl")
                +b.A.ds-link(
                  v-if="isHidden || talent.instagramLink != ''"
                  target='_blank'
                  :href='talent.instagramLink'
                  @click="isHidden ? openSubscriptionInfo() : () => {}"
                )
                  app-icon(name="icon-instagram-outlined" size="2xl")
                +b.A.ds-link(
                  v-if="isHidden || talent.telegramLink != ''"
                  target='_blank'
                  :href='talent.telegramLink'
                  @click="isHidden ? openSubscriptionInfo() : () => {}"
                )
                  app-icon(name="icon-telegram-outlined" size="2xl")

                //- +b.A.ds-link(
                //-   v-if="isHidden || talent.tiktokLink != ''"
                //-   target='_blank'
                //-   :href='talent.tiktokLink'
                //-   @click="isHidden ? openSubscriptionInfo() : () => {}"
                //- )
                //-   app-icon(name="icon-tiktok" size="2xl")
  +b.talent-data-social(
    v-if='talent.videoPresentation || talent.abilityVideo'
  )
    +b.P.text.--font-serif.--weight-bold.--size-lg {{ _('More videos') }}
    row.ma--t-sm
      cell(
        :cols="['12', '6-md']"
        v-if='talent.videoPresentation'
      )
        +b.P.text.--size-sm.--weight-bold {{ _('About yourself') }}
        +b.video-item
          ui-video-view(
            :url="talent.videoPresentation"
            v-slot="{ embedUrl }"
          )
            iframe(
              :src='embedUrl'
              frameborder="0"
              allowfullscreen
            )
      cell.ma--t-sm.ma--t-none-md(
        :cols="['12', '6-md']"
        v-if='talent.abilityVideo'
      )
        +b.P.text.--size-sm.--weight-bold {{ _('Ability video') }}
        +b.video-item
          ui-video-view(
            :url="talent.abilityVideo"
            v-slot="{ embedUrl }"
          )
            iframe(
              :src='embedUrl'
              frameborder="0"
              allowfullscreen
            )
  +b.panel.--bg-white.--br-default.pa--round-xs.pa--round-lg-md(
    :class="{ 'ma--t-lg': !(talent.videoPresentation || talent.abilityVideo)}"
    v-if='lastViewed.length >= 1'
  )
    +b.P.text.--size-lg.--font-serif.--weight-bold {{ _('You recently viewed') }}
    row.ma--t-lg(
      :justify="['center', 'start-xl']"
      :space="['xl-xl']"
    )
      cell(
        :cols="['narrow', 'narrow-lg', '3-xl']"
        v-for='(item, index) in lastViewed'
        :key='index'
      )
        talent-card.recently-view-card(
          :talent='item.talent'
          :recently='true'
        )
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import { ROUTES_NAMES } from '@routes'
import {
  talentUpdateAPI,
} from '@requests/services/services'
import {
  projectsListResource,
} from '@requests/services/catalog'
import { goBack } from '../../utils/back'

const MessageModal = () => import('@app/commons/ui-elements/MessageModal.vue')

export default {
  name: 'talent-detail',
  props: {
    isHidden: Boolean,
    userId: String,
    talentsCatalogUrl: String,
    isAdmin: Boolean,
    talent: Object,
    lastViewed: Array,
    isClient: Boolean,
    isBuy: Boolean,
  },
  components: {
    InnerImageZoom,
  },
  provide() {
    return {
      isAdmin: this.isAdmin,
    }
  },
  data() {
    console.log('lastViewed', this.lastViewed)
    return {
      cabinetUrl: ROUTES_NAMES.TALENT_PROFILE,
      isVerified: this.talent.verified,
      currentPhoto: 0,
      activePhotoWebp: '',
      activePhoto: '',
      isShowTalentContent: Number(this.talent.id) === Number(this.userId) || this.isAdmin || this.isClient,
      hasContactAccess: Number(this.talent.id) === Number(this.userId) || this.isAdmin || this.isBuy,
      projects: [],
      isImageLoad: false,
    }
  },
  computed: {
    ...mapGetters('talentCharacteristics', {
      characteristic: 'getCharacteristic',
      singleChar: 'getSingleCharacteristic',
    }),
    knowledgeOfLanguage() {
      if (this.talent.knowledgeOfEnglish || this.talent.knowledgeOfOtherLanguage) {
        if (this.talent.knowledgeOfEnglish && this.talent.knowledgeOfOtherLanguage) {
          // eslint-disable-next-line prefer-template
          return this._('Англійська') + ', ' + this.talent.knowledgeOfOtherLanguage
        }
        if (this.talent.knowledgeOfOtherLanguage) {
          return this.talent.knowledgeOfOtherLanguage
        }
        return this._('Англійська')
      }
      return this._('Не воллодію')
    },
    photos() {
      const photos = []
      if (this.talent.firstPreviewImage?.base) {
        photos.push(this.talent.firstPreviewImage?.base)
      }
      if (this.talent.secondPreviewImage?.base) {
        photos.push(this.talent.secondPreviewImage.base)
      }
      const selfUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => selfUploaded)
      const adminUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => !selfUploaded)
      const videos = (this.talent.videos || []).map(video => video.videoFile)
      adminUploadedPhoto.forEach(el => {
        photos.push(el.imageLinks.base)
      })
      photos.push(...(videos || []))
      selfUploadedPhoto.forEach(el => {
        photos.push(el.imageLinks.base)
      })
      return photos
    },
    combinedPhotos() {
      const comPhotos = []
      if (this.talent.firstPreviewImage?.base) {
        const avatarOne = {
          imageLinks: this.talent.firstPreviewImage,
        }
        comPhotos.push(avatarOne)
      }

      if (this.talent.secondPreviewImage?.base) {
        const avatarTwo = {
          imageLinks: this.talent.secondPreviewImage,
        }
        comPhotos.push(avatarTwo)
      }
      const selfUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => selfUploaded)
      const adminUploadedPhoto = (this.talent.gallery || []).filter(({ selfUploaded }) => !selfUploaded)
      comPhotos.push(...adminUploadedPhoto)
      comPhotos.push(...(this.talent.videos || []))
      comPhotos.push(...selfUploadedPhoto)
      return comPhotos
    },
  },

  mounted() {
    if (this.isAdmin || this.isClient) {
      this.favoriteListIdRequest()
      this.projectListRequest()
    }

    if (this.isAdmin || this.isClient) {
      this.getProjects()
    }
    this.characteristicsRequest()
    this.switchSlide(this.combinedPhotos[0], 0)
    let touchstartX = 0
    let touchendX = 0

    const checkDirection = () => {
      if (touchendX < touchstartX) this.nextPhoto(this.currentPhoto)
      if (touchendX > touchstartX) this.prevPhoto(this.currentPhoto)
    }

    this.$refs.swipe.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })
    this.$refs.swipe.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      checkDirection()
    })
  },
  methods: {
    ...mapActions('favoriteList', ['favoriteListIdRequest']),

    ...mapActions('favoriteList', ['favoriteListRequest']),
    ...mapActions('unionProjectsList', ['projectListRequest']),
    ...mapActions('talentCharacteristics', ['characteristicsRequest']),
    goBack,

    moderate() {
      talentUpdateAPI.execute({ id: this.talent.id }, { verified: true })
        .then(() => {
          this.$notify(
            {
              text: this._('Статус змінено'),
              type: 'success',
            }
          )
          this.isVerified = true
        })
    },

    openSubscriptionInfo() {
      this.$modal.show(MessageModal, {
        title: this._('Доступ для этой информации доступен только пользователю с оформленной подпиской.'),
      },
      {
        adaptive: true,
      })
    },

    embedVideo(link) {
      const watch = 'watch?v='
      const replacementLink = link.replace(watch, 'embed/')
      return replacementLink
    },
    switchSlide(item, idx) {
      if (item?.videoFile) {
        this.activePhoto = item
      } else {
        this.isImageLoad = false
        this.activePhotoWebp = item?.imageLinks?.webp || 'https://via.placeholder.com/400'
        this.activePhoto = item?.imageLinks?.base || 'https://via.placeholder.com/400'
        const img = new Image();
        img.src = this.activePhoto;

        if (!img.complete) {
          const interval = setInterval(() => {
            if (img.complete) {
              clearInterval(interval);
              this.isImageLoad = true
            }
          }, 50);
        } else {
          this.isImageLoad = true
        }
      }
      this.currentPhoto = idx
    },
    nextPhoto(idx) {
      const index = idx + 1
      if (this.combinedPhotos.length) {
        if (index === this.combinedPhotos.length) {
          this.switchSlide(this.combinedPhotos[0], 0)
          this.$refs.photoBlock.scrollLeft = 0
        } else {
          this.$refs.photoBlock.scrollLeft += 60
          this.switchSlide(this.combinedPhotos[index], index)
        }
      }
    },
    prevPhoto(idx) {
      const index = idx - 1
      if (this.combinedPhotos.length) {
        if (0 === idx) {
          this.switchSlide(this.combinedPhotos[this.combinedPhotos.length - 1], this.combinedPhotos.length - 1)
          this.$refs.photoBlock.scrollLeft = this.$refs.photoBlock.scrollWidth
        } else {
          this.$refs.photoBlock.scrollLeft -= 60
          this.switchSlide(this.combinedPhotos[index], index)
        }
      }
    },
    getProjects() {
      this.isLoad = true
      const params = {
        filters: {
          status: 0,
        },
      }
      projectsListResource.execute(params).then(res => {
        const { data } = res
        this.projects = data.items
      }).finally(() => {
        this.isLoad = false
      })
    },
  },
}
</script>
<style scoped lang="scss">
.light-box-wrapper {
  width: 100%;
}

.image-wrap {
  position: relative;

  &_loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    top: 0;

    &_spinner {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.controls {
  position: unset;

  &-left {
    position: absolute;
    top: 49%;
    left: 16px;
    z-index: 9999;

    width: 48px;
    height: 48px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: rotate(180deg);

    cursor: pointer;
  }

  &-right {
    position: absolute;
    top: 49%;
    right: 16px;
    z-index: 9999;

    width: 48px;
    height: 48px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}

.talent-photo-view__photo {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

@media (max-width: 600px) {
  .talent-photo-view__photo {
    height: 203px;
  }
  .talent-photo-view {
    height: 203px;
  }
}
</style>

<style scoped>
  .talent-image-box {
    border-radius: 15px;
    width: 100%;
  }
  .contacts-info {
    background-color: #FAFAFF;
    border-radius: 20px;
    border: 1px solid #EBEBFE;
    position: relative;
  }
  .contact-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: 'center'
  }
  .contact-header-icon-wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .contacts-info-disabled {
    background-color: #FAFAFF1A;
    backdrop-filter: blur(13px);
    border-radius: 16px;
    border: 1px solid #EBEBFE;
  }

  .contacts-info-buy {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-contact-btn {
    width: 198px;
    height: 48px;
    border: 2px solid #7069A9;
    background-color: transparent;
    border-radius: 50px;
    color: #7069A9;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    transition: all .3s;
    cursor: pointer;
  }

  .add-contact-btn:hover {
    border: 2px solid #7069A9;
    color: #7069A9;
  }
  .contacts-info-buy-disabled {
    display: none
  }

  .text-green {
    color: #5CA300;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
  }

  .contact-disabled {
    display: none
  }

  .plus-icon {
    width: 24px;
    height: 24px;
  }

  .contact-header-text {
    color: #514A82;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }

  .green-text {
    color: #5CA300;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    cursor: pointer;
  }

  .casting-add-wrapper {
    cursor: pointer;
    background-color: #63B000;
    width: 247px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    color: #FFFFFF;
    border-radius: 40px;
    transition: all .3s;
  }

  .casting-add-wrapper:hover {
    background-color: #5CA300;

  }
  .share-icon {
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    .info-desktop {
      display: none;
    }
  }

  .panel {
    box-shadow: none
  }

  .pseudo-slider__photo-block {
    column-gap: 8px;
  }

  .pseudo-slider__item {
    width: 93px;
    height: 93px;
    border-radius: 12px;
    margin: 0;
  }
  .pseudo-slider__image {
    width: 93px;
    height: 93px;
  }

  @media (max-width: 800px) {
    .pseudo-slider__item {
      width: 67px;
      height: 67px;
      border-radius: 10px;

    }
    .pseudo-slider__image {
      width: 67px;
      height: 67px;
    }

    .name-text {
      font-size: 24px;
    }
    /* .green-text {
      display: none;
    } */
  }

  .name-wrapper {
    padding-bottom: 8px;
  }

  .cell-wrapper {
    padding-bottom: 24px;
  }

  .back-wrapper {
    padding-top: 22px;
    font-size: 14px;
    font-weight: 600;
  }
  .heart-wrapper {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
</style>
