import { prefixLanguage } from '@utils/urls'
import CabinetLayout from '@app/LK/CabinetLayout'
import ClientRegistrationFinish from '@app/authorization/ClientRegistrationFinish.vue';

const ROUTES_NAMES = {
  CABINET: 'person-cabinet',

  /* Projects */
  PROJECTS_LAYOUT: 'projects-layout',
  PROJECTS: 'projects',
  PROJECT_CREATE: 'project-create',
  PROJECT_SUMMARY: 'project-summary',
  PROJECT_EDIT: 'project-edit',
  PROJECT_INFO: 'project-info',

  /* Characters */
  CHARACTER_INFO: 'character-info',

  /* Favorites */
  FAVORITES: 'favorites',

  /* Errors */
  NO_ACCESS: 'no-access',
  NOT_FOUND: 'not-found',

  /* Talent */
  TALENT_PROFILE: 'talent-profile',
  TALENT_PROFILE_SETTINGS: 'talent-settings',

  TALENTS_LIST: 'talents-list',

  REGISTRATION_CLIENT_FINISH: 'registration-client-finish',
}

export { ROUTES_NAMES }

function checkRoutePageFn(page) {
  const projectsLink = document.getElementById('js-projects')
  // const LOGO_LINKS = Array.from(document.getElementsByClassName('logo-link'))
  if ('projects' === page.name) {
    if (projectsLink) {
      projectsLink.classList.add('header-link', 'is-active')
    }
    // LOGO_LINKS.forEach(logo => {
    //   logo.setAttribute('style', 'pointer-events: none')
    // })
  } else if (projectsLink) {
    projectsLink.classList.remove('header-link', 'is-active')
  }
  // LOGO_LINKS.forEach(logo => {
  //   logo.setAttribute('style', 'pointer-events: initial')
  // })
}

function checkPermission(to, from, next) {
  checkRoutePageFn(to)
  const { ROLE } = window
  const { meta: { permissions = [] } } = to

  const isAccessed = permissions.includes(ROLE)
  setTimeout(() => {
    // if (isActiveClient) {
    //   next()
    //
    //   return
    // }
    //
    // if (!isActiveClient) {
    //   next({ name: ROUTES_NAMES.REGISTRATION_CLIENT_FINISH })
    //
    //   return
    // }

    if (isAccessed) {
      next()
    } else {
      next({ name: ROUTES_NAMES.NO_ACCESS })
    }
  }, 100)
}

function checkTalentPermission(to, from, next) {
  checkRoutePageFn(to)
  const { ROLE } = window
  const { meta: { permissions = [] } } = to
  const isAccessed = permissions.includes(ROLE)
  setTimeout(() => {
    if (isAccessed) {
      next()
    } else {
      next({ name: ROUTES_NAMES.NO_ACCESS })
    }
  }, 100)
}

export const ROUTES = [
  {
    component: CabinetLayout,
    path: prefixLanguage('/cabinet/'),
    name: ROUTES_NAMES.CABINET,
    redirect: 'admin' === window.ROLE || 'client' === window.ROLE ? { name: ROUTES_NAMES.PROJECTS } : { name: ROUTES_NAMES.TALENT_PROFILE },
    meta: { permissions: ['admin', 'client', 'talent'] },
    children: [
      {
        component: () => import('@app/LK/lk-admin/ProjectsLayout'),
        path: 'layout',
        name: ROUTES_NAMES.PROJECTS_LAYOUT,
        meta: { permissions: ['admin', 'client'] },
        redirect: { name: ROUTES_NAMES.PROJECTS },
        beforeEnter: checkPermission,
        children: [
          {
            component: () => import('@app/LK/lk-admin/Projects'),
            path: 'projects',
            name: ROUTES_NAMES.PROJECTS,
            meta: { permissions: ['admin', 'client'] },
            beforeEnter: checkPermission,
          },
          {
            component: () => import('@app/LK/lk-admin/SingleProject'),
            path: 'project/:id',
            name: ROUTES_NAMES.PROJECT_INFO,
            meta: { permissions: ['admin', 'client'] },
            beforeEnter: checkPermission,
          },
          {
            component: () => import('@app/LK/lk-admin/TalentsList'),
            path: 'talents-list/',
            name: ROUTES_NAMES.TALENTS_LIST,
            meta: { permissions: ['admin', 'client'] },
            beforeEnter: checkPermission,
          },
          // {
          //   component: () => import('@app/LK/lk-admin/CharacterDetail'),
          //   path: 'character/:id',
          //   name: ROUTES_NAMES.CHARACTER_INFO,
          //   meta: { permissions: ['admin'] },
          //   beforeEnter: checkPermission,
          // },
        ],
      },
      {
        component: () => import('@app/LK/lk-talent/TalentProfile'),
        path: 'profile/',
        name: ROUTES_NAMES.TALENT_PROFILE,
        meta: { permissions: ['talent'] },
        beforeEnter: checkTalentPermission,
      },
      {
        component: () => import('@app/LK/lk-talent/TalentProfile'),
        path: 'talents/:id/edit',
        name: ROUTES_NAMES.TALENT_PROFILE,
        meta: { permissions: ['admin'] },
        beforeEnter: checkTalentPermission,
      },
      {
        component: () => import('@app/LK/lk-talent/TalentSettings'),
        path: 'profile/settings/',
        name: ROUTES_NAMES.TALENT_PROFILE_SETTINGS,
        meta: { permissions: ['talent'] },
        beforeEnter: checkTalentPermission,
      },
      // {
      //   component: () => import('@app/LK/lk-admin/Projects'),
      //   path: prefixLanguage('/cabinet/projects/'),
      //   name: ROUTES_NAMES.PROJECTS,
      //   meta: { permissions: ['admin'] },
      //   beforeEnter: checkPermission,
      // },
      {
        component: () => import('@app/LK/lk-admin/CreateProject'),
        path: prefixLanguage('create-project'),
        name: ROUTES_NAMES.PROJECT_CREATE,
        meta: { permissions: ['admin', 'client'] },
        beforeEnter: checkPermission,
      },
      {
        component: () => import('@app/LK/lk-admin/ProjectSummary'),
        path: prefixLanguage('project-summary/:id'),
        name: ROUTES_NAMES.PROJECT_SUMMARY,
        meta: { permissions: ['admin', 'client'] },
        beforeEnter: checkPermission,
      },
      {
        component: () => import('@app/LK/lk-admin/EditProject'),
        path: prefixLanguage('project-edit/:id'),
        name: ROUTES_NAMES.PROJECT_EDIT,
        meta: { permissions: ['admin', 'client'] },
        beforeEnter: checkPermission,
      },
      // {
      //   component: () => import('@app/LK/lk-admin/SingleProject'),
      //   path: prefixLanguage('project/:id'),
      //   name: ROUTES_NAMES.PROJECT_INFO,
      //   meta: { permissions: ['admin'] },
      //   beforeEnter: checkPermission,
      // },
      // {
      //   component: () => import('@app/LK/lk-admin/EditCharacter'),
      //   path: prefixLanguage('character-edit/:id'),
      //   name: ROUTES_NAMES.CHARACTER_EDIT,
      //   meta: { permissions: ['admin'] },
      //   beforeEnter: checkPermission,
      // },
      // {
      //   component: () => import('@app/LK/lk-admin/CharacterDetail'),
      //   path: prefixLanguage('character/:id'),
      //   name: ROUTES_NAMES.CHARACTER_INFO,
      //   meta: { permissions: ['admin'] },
      //   beforeEnter: checkPermission,
      // },
      {
        component: () => import('@app/LK/lk-admin/FavoritesPage'),
        path: prefixLanguage('favorites'),
        name: ROUTES_NAMES.FAVORITES,
        meta: { permissions: ['admin', 'client'] },
        beforeEnter: checkPermission,
      },
    ],
  },
  {
    // TODO: Add permissions check for this page
    component: () => import('@app/CreateProject/CreateProject.vue'),
    path: '/create-project',
  },
  {
    component: ClientRegistrationFinish,
    name: ROUTES_NAMES.REGISTRATION_CLIENT_FINISH,
    path: '/auth/registration-client-finish',
  },
  {
    component: () => import('@app/error-pages/403'),
    path: prefixLanguage('/cabinet/no-access/'),
    name: ROUTES_NAMES.NO_ACCESS,
    meta: { permissions: ['admin', 'client', 'talent'] },
  },
  {
    component: () => import('@app/error-pages/404'),
    path: prefixLanguage('/:nothing(.*)'),
    name: ROUTES_NAMES.NOT_FOUND,
    meta: { permissions: ['admin', 'client', 'talent'] },
  },
]
